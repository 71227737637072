/* @font-face {
  font-family: "AbadiMTStd";
  src: url(./fontfamily/AbadiMTStd.otf);
 } */
 @font-face {
  font-family: "gazpacho";
  src: url(./fontfamily/Gazpacho\ Medium.ttf);
 }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-image{
  display: flex;
}
.active{
  border: 1px solid red;
}
.custam{ 
  width: 50% !important;
  box-shadow: 2px 2px 1px red;
  border-radius: 50%!important;
}
.background{
  background-color: #123B3A!important ;
  color: hsl(0, 0%, 100%)!important;
  width: 100px;
  height: 65px;
  font-size: 20px;

}

h1, h2, h3, h4, h5, h6, p, span, label, a, button, label,li{
  font-family: gazpacho !important;
}