.c-number {
    padding-right: 1rem;
    font-size: 18px;
    line-height: 38px;
    line-height: 2.25rem;
    font-weight: 600;
}
.font_cstm{
    font-size:14px;
}
.c-step-header {
    color: #4e1f0f;
    text-transform:uppercase;
}

.c-description {
    font-size: 14px;
    line-height: 1.5rem;
}
.font-weight-bold{
    font-weight: 600;
}
.c-description{
    text-align: center;
    min-height: 35px;
    text-transform: capitalize;
}
.img_bg{
    position: relative;
}
.img_content {
    position: absolute;
    top: 50%;
    max-width: 650px;
    left: 10%;
    color: #fff;
    transform: translateY(-50%);
}
.border_line{
    position: relative;
}
.border_line>ul{
    line-height: 30px;
    padding-top: 10px;
}
.border_line:before{
    content: "";
    background-color: #e1c077;
    width: 80%;
    height: 1px;
    position: absolute;

}
.lang_button{
    height: 35px;
    font-size: 16px;
    background-color: #efd38b;
    color: #1a0705;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 0 25px;
    margin-top: 10px;
}
.top_btn{
    background-color: #1a0705;
    color: #efd38b;   
}
.img_inner{
    /* position: absolute;
    top:0; */
}
.img_inner {
    position: relative;
}
.linewrapper{
    position: relative;
    color:#4e1f0f;
}
.linewrapper::before{
    position: absolute;
    content: "";
    background-color: #ff8301;
    height: 1px;
    width: 100%;
    bottom: 0;
}
.linewrapper::after{
    position: absolute;
    content: "";
    background-image: url('../images/plate.png');
    background-repeat: no-repeat;
    width: 105px;
    height: 230px;
    right: 0;
    bottom: -90px;
    z-index: 99999;
}
.imagePlate {
    position: absolute;
    top: 50%;
    z-index: 99;
    right: 0;
    transform: translateY(-50%);
}

.leaves_bg::before{
    position: absolute;
    content: "";
    background-image: url('../images/leaves.png');
    background-repeat: no-repeat;
    height: 100px;
    left: 0;
    top: -30px;
    z-index: 99999;
    width: 100px;
}
.slick-dots li button:before {
    font-size: 18px;
    color:#4e1f0f;
}

.slick-dots li.slick-active button:before {
    color: #4e1f0f;
}
@media(max-width:450px){
    .w-75-custom {
          width:75%; 
          margin: 0 auto; 
    }
    .slick-slider{
        overflow: hidden;
    }
    .img_inner .slick-prev {
        left: 20px;
    }
    .img_inner .slick-next{
        right: 20px;
    }
    .img_inner .slick-prev:before, .img_inner .slick-next:before {
        color: #4e1f0f;
    }
    .btnCen{
        text-align:center;
    }
}