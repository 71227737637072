.footer{
    background-color: #4e1f0f;
    padding:80px 0;
    color:#fff;
}
.footer a{
    color:#fff;
    text-decoration: none;
}

.footer h6{
    color: #d29e00;
    font-size: 18px;
    line-height: 1.2;
}
.footer ul{
    line-height: 1.8;
}
.footer_font{
    font-size: 12px;
    font-weight: 600;
}
.social-media--list .social-media__item {
    opacity: .65;
    transition: opacity .2s,color .2s;
}
.social-media--list .social-media__item:hover {
    opacity: 1;
    transition: opacity .2s,color .2s;
}
.text-subdued .UnderLineText{
    background: linear-gradient(to right,currentColor,currentColor) 0 min(100%,1.35em) / 100% 1px no-repeat;
    transition: background-size .3s ease-in-out;
}
.text-subdued .UnderLineText:hover{
background-size: 0 1px;
}
.text-subdued span:first-child{
    margin-right: 10px;  
}
.social-media__item{
    margin-right: 10px;
}
@media(max-width:992px){
    .footer_font{
        font-size: 13px;
        font-weight: 600;
    } 
    .footer h6{
        font-size: 18px;
    } 
}
@media(max-width:768px){
    .footer_font{
        font-size: 13px;
        font-weight: 600;
    } 
    .footer h6{
        font-size: 18px;
    } 
}
@media(max-width:576px){
    .footer_font{
        font-size: 12px;
        font-weight: 600;
    }
    .footer h6{
        font-size: 18px;
        padding:10px 0;
    } 
    .tabsContent{
        display:none;
    }
    .tabsContent.activeDisplay{
        display:block;
    }
    .tabsHeading{
        position:relative;
    }
    .tabsHeading:before {
        background: url(https://cdn.shopify.com/s/files/1/0014/3514/0183/files/plus.png?v=1677132611) no-repeat center;
        width: 30px;
        height: 12px;
        background-size: contain;
        content: "";
        position: absolute;
        right: 10px;
        top: 0;
    } 
    .tabsHeading.classHeading:before {
        background: url(https://cdn.shopify.com/s/files/1/0014/3514/0183/files/minus.png?v=1677132611) no-repeat center;
    } 
}