 @font-face {
  font-family: "AbadiMTStd";
  src: url(../fontfamily/Gazpacho\ Medium.ttf);
 }

.step-progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #f0f0f0; */
  padding: 10px;
  border-radius: 5px;
}
.cstm_input{
  border: 1px solid #111;
  border-radius: 7px;
  font-size: 12px;
  padding: 0.25rem;
}
.fs-7{font-size: 13px;}
.cstm_input.activebutton{
  color:#fff;
}
.form-label{
  font-size: 13px; 
}
.step-0,
.step-1,
.step-2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.step-name {
  color: rgb(255, 87, 51);
  background: #cfcdcd;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: auto 0;
}

.step-divider {
  flex: 1 1;
  height: 3px;
  width: 100%;
}

/* Add styles for completed steps */
.step-name.completed {
  background: #100505;
}

.step-divider.completed {
  background-color: #100505;
}
#userGender span{
  font-size:12px;
}
.button {
  width: 120px;
  height: 50px;
  font-size: 12px;
  background-color: #003f3a;
  color: white;
  font-weight: bold;
  font-family: AbadiMTStd;
}

.button:hover img {
  filter: invert(1);
}
.button:hover {
  /* background-color: #4e1f0f; */
  background-color: #1c3d36;
  color: white;
}
.imagecontainerdiv {
  /* border: 1px solid #4e1f0f; */
  height: 80px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  opacity: 1 !important;
}

.activebutton {
  background-color: #4e1f0f;
}

.button:hover img{
  filter: invert(0);
}
.activebutton img {
  filter: invert(1);
}

.dim {
  opacity: 0.5;
}

.adjudt {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: AbadiMTStd;
}

.adjudt1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 15px;
}
.step-progress-bar p {
  color: #100505;
  text-align: center;
  font-family: AbadiMTStd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: 12%;
  /* padding: 0rem 1rem 0 0; */
  margin: 0;
}

.form-check-label.selected-gender{
  border: 1px solid red;
}
.form-check-label{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 1rem;
  margin: 1rem; */
  /* border: 1px solid black; */
  border-radius: 50px;
  /* height: 42px;
  width: 42px; */
}
.form-check-label.activebutton, .hairQuiz .activebutton{
  background-color: #fff;
}
.form-check-label.activebutton svg g circle, .hairQuiz .activebutton svg g circle{
  fill:#4e1f0f;
  stroke: #fff;
}
.form-check-label.activebutton svg g path, .hairQuiz .activebutton svg g path{
  fill:#fff;
}
.hairQuiz .activebutton svg.notchecked g path{
  fill:#fff;
  stroke:#fff;
}
.hairQuiz .activebutton svg.revertsvg g path{
  fill:#4e1f0f;
  stroke:#fff;
}

.borderBottomstyle{
  border-bottom: 2px solid #EAC96E ; 
}

.textstyle{
 font-size: 14px;
}

::placeholder {
  color: #CFCDCD !important ;
  opacity: 0.5 !important;
  font-size: 12px !important;
}
.infoIcon{
  display: none;
}
.infoIcon{
  width: 16px;
  height: 16px;
  margin-top: 4px;
}
.action{
  transform :0.3s ease;
  /* transition: .5s all */

}
.loaderContainer{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.errorstyle{
  font-size:10px;
  color:red
}

.UserCheckContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #7b7185;
  padding: 1rem;
  width: 75%;
}
.UserCheckContainerOverlay{
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  top: 0%;
  left: 0%;
  opacity: 0.5; 
}

.miniloader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: #fff; */
  z-index: 99;
}
.loaderOverlay{
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  top: 0%;
  left: 0%;
  opacity: 0.5; 
}
.checkboxDataStyle{
  font-size: 11px;
}
.checkboxStyle{
  width: 10px;
}
/* .activebutton{
  border-radius: 1px solid #4e1f0f;
} */
.activebutton svg{
  /* background-color: #4e1f0f; */
  fill:#4e1f0f;
  /* stroke:#fff; */
}
.activebutton svg path{
  stroke:#fff; 
}
@media (min-width:1190px) {
  .step-progress-bar{
    padding: 10px 6em;
  }
}

main {
  min-height: 500px;
}

body:has(.loaderContainer) {
  overflow: hidden;
}